export function flattenObject(obj: any): Record<string, any> {
  const result: Record<string, any> = {}

  function flatten(nested: any, prevKey = '') {
    for (const [key, value] of Object.entries(nested)) {
      const newKey = prevKey ? `${prevKey}.${key}` : key
      if (isArrayOfObjects(value))
        (<any[]>value).forEach((val: any, index: number) => flatten(val, `${newKey}[${index}]`))
      else if (isObject(value) && !isArray(value))
        flatten(value, newKey)
      else
        result[newKey] = value
    }
  }

  flatten(obj)

  return result
}

function isObject(obj: any): boolean {
  return typeof obj === 'object' && obj != null
}

export function isArray(obj: any): boolean {
  return isObject(obj) && Array.isArray(obj)
}

export function isNullOrUndefined(object: any): object is null | undefined {
  return object === null || object === undefined
}

export function isNumber(value: any): boolean {
  if (isNullOrUndefined(value) || typeof value === 'boolean' || String(value).trim() === '')
    return false

  return !Number.isNaN(toNumber(value))
}

export function toNumber(value: any): number {
  const val = value && value.toString().replace(/\s/g, '').replace(',', '.')

  return Number(val)
}

function isArrayOfObjects(obj: any): boolean {
  return obj != null && Array.isArray(obj) && obj.every(val => isObject(val))
}

export function arraysHasSameElements<T>(array1?: T[], array2?: T[]): boolean {
  const sortedArray1 = [...(array1 || [])].sort()
  const sortedArray2 = [...(array2 || [])].sort()

  return areArraysSame<T>(sortedArray1, sortedArray2)
}

export function areArraysSame<T>(array1: T[], array2: T[]): boolean {
  const a1 = array1 || []
  const a2 = array2 || []

  return a1.length === a2.length && a1.every((value, index) => value === a2[index])
}

export function uniqueValues<T>(array: T[]): T[] {
  return Array.from(new Set(array))
}

export function splitName(name: string): [string, string] {
  const firstSpaceIndex = (name || '').indexOf(' ')
  if (firstSpaceIndex >= 0) {
    const firstPart = name.substring(0, firstSpaceIndex)
    const secondPart = name.substring(firstSpaceIndex + 1)

    return [firstPart, secondPart]
  }

  return [name || '', '']
}

export function createRange(start: number, end: number): number[] {
  return Array.from({ length: end - start + 1 }, (_, index) => start + index)
}

export function appendPrefixToKeys(record: Record<string, any>, prefix: string): Record<string, any> {
  return Object.keys(record || {}).reduce((newRecord, key) => ({ ...newRecord, [`${prefix}${key}`]: record[key] }), {})
}

export function mapKeys(record: Record<string, any>, mapping: Record<string, string>): Record<string, any> {
  return Object.keys(record || {}).reduce((newRecord, key) => ({
    ...newRecord,
    [mapping[key] || key]: record[key],
  }), {})
}

export function appendObjectsToEnd<T extends { id: number }>(objects: T[], newObjects: T[]): T[] {
  const copy = [...objects]
  const ids = copy.map(obj => obj.id)
  for (const obj of newObjects) {
    if (!ids.includes(obj.id))
      copy.push(obj)
  }

  return copy
}

export function appendObjectsToStart<T extends { id: number }>(objects: T[], newObjects: T[]): T[] {
  const copy = [...objects]
  const ids = copy.map(obj => obj.id)
  const reversed = [...newObjects].reverse()
  for (const obj of reversed) {
    if (!ids.includes(obj.id))
      copy.unshift(obj)
  }

  return copy
}

export function normalizeString(value: string | null | undefined): string {
  return removeAccents(value || '').toLowerCase()
}

export function highlightString(valueWithoutAccent: string, originalValue: string, term: string): string | undefined {
  const index = valueWithoutAccent.indexOf(term)
  if (index >= 0)
    return `${originalValue.substring(0, index)}<b>${originalValue.substring(index, index + term.length)}</b>${originalValue.substring(index + term.length)}`

  return undefined
}

export function removeAccents(value: string): string {
  return value.normalize('NFD').replace(/[\u0300-\u036F]/g, '')
}

export function roundNoTrailingZeros(value: string | number | null | undefined, maxFractionDigits: number): string | undefined {
  return isNullOrUndefined(value) || value === '' ? undefined : String(+Number.parseFloat(String(value)).toFixed(maxFractionDigits))
}

export function uuidv4(): string {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
    (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16),
  )
}

export function capitalizeString(str: string): string {
  return (str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()).replaceAll('_', ' ')
}

export function highlightValueIfChanged(oldValue: string | null | undefined, newValue: string | null | undefined, newLine: boolean = true, highlightColor: string = 'text-warning'): string | undefined {
  if (!oldValue && !newValue)
    return undefined
  else if (oldValue && newValue && oldValue !== newValue)
    return `<span class="${highlightColor} fw-600">${newValue}</span> ${newLine ? '<br/>' : ''} <del class="text-muted small">${oldValue}</del>`
  else if (!oldValue && newValue)
    return `<span class="${highlightColor} fw-600">${newValue}</span>`
  else if (oldValue && newValue === '')
    return `<del class="text-muted">${oldValue}</del>`
  else
    return oldValue || undefined
}

export function constructPairs<T>(firstList: T[], secondList: T[] | undefined): [T | undefined, T | undefined][] {
  if (!secondList)
    secondList = firstList

  const maxLen = Math.max(firstList.length, secondList.length)
  const pairs: [T | undefined, T | undefined][] = []

  for (let i = 0; i < maxLen; i++) {
    pairs.push([i < firstList.length ? firstList[i] : undefined,
      i < secondList.length ? secondList[i] : undefined])
  }

  return pairs
}

export function formatPercent(num: number | string | null | undefined, emptyValue = ''): string {
  if (isNullOrUndefined(num) || !isNumber(num))
    return emptyValue

  const { n } = useI18n()

  return `${n(toNumber(num), { key: 'percent', minimumFractionDigits: 2 }, 'sk-SK')}`
}

export function formatFileSize(bytes: number) {
  if (!bytes || bytes < 0) return '0 bytes'
  if (bytes === 1) return '1 byte'

  const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(bytes) / Math.log(1000))
  const decimalPlaces = i >= 2 ? 2 : 0 // we only want to show decimal places for MB and bigger
  const fileSize = (bytes / Math.pow(1000, i)).toFixed(decimalPlaces)

  return `${fileSize} ${sizes[i]}`
}

export function isInputTextInOptions(options: Array<{ label: string; value: any }> | Record<string, any>, inputText: string): boolean {
  const normalizedInput = normalizeString(inputText.trim())
  let optionsArray

  if (Array.isArray(options)) {
    optionsArray = options
  }
  else {
    optionsArray = Object.values(options)
  }

  return optionsArray.some(
    option => normalizeString(option.label) === normalizedInput,
  )
}
