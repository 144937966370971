import type { FetchDataState } from '@/composables/fetch-paged-data-state'
import type { Auction, AuctionList, AuctionsListToMergeListParams } from '@apiTypes'
import { ref } from 'vue'
import { useAuctionsStore } from '@store/auction'
import { OrderEnum5 } from '@apiTypes'

export function useMergeInfo(): { enabled: Ref<boolean> } {
  const enabled = ref(import.meta.env.VITE_MERGE_ENABLED === 'true')

  return { enabled }
}

export function useMergeAuctions(ids: Ref<string[]>, order: Ref<OrderEnum5 | null>): FetchDataState<Auction[]> {
  const loading = ref(false)
  const error = ref('')
  const auctions = ref<Auction[]>([])
  const auctionsStore = useAuctionsStore()
  const { t } = useI18n()

  function fetch() {
    loading.value = true
    error.value = ''

    const query: AuctionsListToMergeListParams = {
      ids: toValue(ids), order: [toValue(order) || OrderEnum5.ValueCreatedAt],
    }

    auctionsStore.fetchMergeAuctions(query).then(response => {
      auctions.value = response
    }).catch(() => {
      error.value = t('Could not load auctions')
    }).finally(() => (loading.value = false))
  }

  watchEffect(fetch)

  const refresh = () => fetch()

  return { data: auctions, loading, error, refresh }
}

export function useMergedAuctions(id: Ref<number | undefined>): FetchDataState<AuctionList[]> & { loaded: Ref<boolean> } {
  const loading = ref(false)
  const loaded = ref(false)
  const error = ref('')
  const auctions = ref<AuctionList[]>([])
  const auctionsStore = useAuctionsStore()
  const { t } = useI18n()

  function fetch() {
    const auctionId = toValue(id)
    if (!auctionId)
      return

    loading.value = true
    error.value = ''

    auctionsStore.fetchMergedAuctions(auctionId).then(response => {
      auctions.value = response
    }).catch(() => {
      error.value = t('Could not load auctions')
    }).finally(() => {
      loading.value = false
      loaded.value = true
    })
  }

  watchEffect(fetch)

  const refresh = () => fetch()

  return { data: auctions, loading, loaded, error, refresh }
}
