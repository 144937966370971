export const publicPages = [
  'index',
  'login',
  'login-social-microsoft',
  'error',
  'register',
  'password-reset',
  'password-reset-confirm-uid-token',
  'carrier-chash-auction-hash',
  'invitations-accept-invite-hash',
  'account-confirm-email-hash',
  'privacy-preferences-hash',
]
